<div
  class="w-full bg-black/80 backdrop-blur-[96px] shadow-lg ring-1 ring-white/10">
  <div class="max-w-screen-2xl w-full mx-auto px-8 py-12">
    <div
      class="grid md:gap-x-32 md:gap-y-0 gap-y-8 md:grid-flow-col auto-cols-min md:justify-start justify-center whitespace-nowrap">
      <div>
        <h3 class="m-0 mb-3">Liens</h3>
        <ul class="list-none m-0 p-0 leading-7">
          @for (menuItem of menuItems; track menuItem.label) {
            <li>
              <a
                [routerLink]="menuItem.path"
                class="link transition-all block hover:translate-x-1 hover:!text-white"
                >{{ menuItem.label }}</a
              >
            </li>
          }
        </ul>
      </div>
      <div>
        <h3 class="m-0 mb-3">Nous suivre</h3>
        <div class="flex gap-2">
          <a
            href="https://discord.gg/8cAAa7J"
            target="_blank"
            class="no-underline text-[var(--text-color)] flex justify-center cursor-pointer p-2 group"
            pTooltip="Discord"
            tooltipPosition="bottom">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="32"
              height="32"
              viewBox="0 -6 128 96"
              fill="#ffffff"
              class="group-hover:fill-[#5865F2] transition-colors">
              <path
                d="M107.7,8.07A105.15,105.15,0,0,0,81.47,0a72.06,72.06,0,0,0-3.36,6.83A97.68,97.68,0,0,0,49,6.83,72.37,72.37,0,0,0,45.64,0,105.89,105.89,0,0,0,19.39,8.09C2.79,32.65-1.71,56.6.54,80.21h0A105.73,105.73,0,0,0,32.71,96.36,77.7,77.7,0,0,0,39.6,85.25a68.42,68.42,0,0,1-10.85-5.18c.91-.66,1.8-1.34,2.66-2a75.57,75.57,0,0,0,64.32,0c.87.71,1.76,1.39,2.66,2a68.68,68.68,0,0,1-10.87,5.19,77,77,0,0,0,6.89,11.1A105.25,105.25,0,0,0,126.6,80.22h0C129.24,52.84,122.09,29.11,107.7,8.07ZM42.45,65.69C36.18,65.69,31,60,31,53s5-12.74,11.43-12.74S54,46,53.89,53,48.84,65.69,42.45,65.69Zm42.24,0C78.41,65.69,73.25,60,73.25,53s5-12.74,11.44-12.74S96.23,46,96.12,53,91.08,65.69,84.69,65.69Z" />
            </svg>
          </a>
          <a
            href="https://www.youtube.com/c/BeatSaberFR"
            target="_blank"
            class="no-underline text-[var(--text-color)] flex cursor-pointer p-2 group"
            pTooltip="YouTube"
            tooltipPosition="bottom">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="#ffffff"
              width="34"
              height="34"
              viewBox="1.2 -1 30 34"
              stroke="#ffffff"
              class="group-hover:fill-[#FF0000] group-hover:stroke-[#FF0000] transition-colors">
              <g
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke="#CCCCCC"
                stroke-width="0.192" />
              <g>
                <path
                  d="M12.932 20.459v-8.917l7.839 4.459zM30.368 8.735c-0.354-1.301-1.354-2.307-2.625-2.663l-0.027-0.006c-3.193-0.406-6.886-0.638-10.634-0.638-0.381 0-0.761 0.002-1.14 0.007l0.058-0.001c-0.322-0.004-0.701-0.007-1.082-0.007-3.748 0-7.443 0.232-11.070 0.681l0.434-0.044c-1.297 0.363-2.297 1.368-2.644 2.643l-0.006 0.026c-0.4 2.109-0.628 4.536-0.628 7.016 0 0.088 0 0.176 0.001 0.263l-0-0.014c-0 0.074-0.001 0.162-0.001 0.25 0 2.48 0.229 4.906 0.666 7.259l-0.038-0.244c0.354 1.301 1.354 2.307 2.625 2.663l0.027 0.006c3.193 0.406 6.886 0.638 10.634 0.638 0.38 0 0.76-0.002 1.14-0.007l-0.058 0.001c0.322 0.004 0.702 0.007 1.082 0.007 3.749 0 7.443-0.232 11.070-0.681l-0.434 0.044c1.298-0.362 2.298-1.368 2.646-2.643l0.006-0.026c0.399-2.109 0.627-4.536 0.627-7.015 0-0.088-0-0.176-0.001-0.263l0 0.013c0-0.074 0.001-0.162 0.001-0.25 0-2.48-0.229-4.906-0.666-7.259l0.038 0.244z" />
              </g>
            </svg>
          </a>
          <a
            href="https://twitch.tv/BeatSaberFR"
            target="_blank"
            class="no-underline text-[var(--text-color)] flex items-center cursor-pointer p-2 group"
            pTooltip="Twitch"
            tooltipPosition="bottom">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="28"
              height="28"
              viewBox="0 0 64 64"
              fill="#ffffff"
              fill-rule="evenodd"
              class="group-hover:fill-[#6441A4] transition-colors">
              <path
                d="M5.7 0L1.4 10.985V55.88h15.284V64h8.597l8.12-8.12h12.418l16.716-16.716V0H5.7zm51.104 36.3L47.25 45.85H31.967l-8.12 8.12v-8.12H10.952V5.73h45.85V36.3zM47.25 16.716v16.716h-5.73V16.716h5.73zm-15.284 0v16.716h-5.73V16.716h5.73z" />
            </svg>
          </a>
          <a
            href="https://x.com/beatsaberfr"
            target="_blank"
            class="no-underline text-[var(--text-color)] flex items-center cursor-pointer p-2 group"
            pTooltip="X"
            tooltipPosition="bottom">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="28"
              height="28"
              viewBox="0 0 300 271"
              fill="#ffffff"
              stroke-width="10"
              stroke="transparent"
              class="group-hover:fill-black group-hover:stroke-white transition-colors">
              <path
                d="m236 0h46l-101 115 118 156h-92.6l-72.5-94.8-83 94.8h-46l107-123-113-148h94.9l65.5 86.6zm-16.1 244h25.5l-165-218h-27.4z" />
            </svg>
          </a>
        </div>
      </div>
    </div>
  </div>
</div>
