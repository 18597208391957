@if (!touchUI) {
  <p-button (onClick)="toggleOverlay($event)" [text]="true" [loading]="loading">
    @if (selectedEmoji && !loading) {
      <span
        [innerHTML]="selectedEmoji | trustHTML"
        class="flex items-center pt-1 w-[22px] h-[34px]"></span>
    }
  </p-button>

  <p-overlayPanel (onShow)="showEmojis()" [styleClass]="styleClass" #op>
    <ng-template pTemplate="content">
      <div id="picker">
        <div class="w-96"></div>
      </div>
    </ng-template>
  </p-overlayPanel>
} @else {
  <p-button (onClick)="showDialog()" [text]="true" [loading]="loading">
    @if (selectedEmoji && !loading) {
      <span
        [innerHTML]="selectedEmoji | trustHTML"
        class="flex items-center pt-1 w-[22px] h-[34px]"></span>
    }
  </p-button>

  <p-confirmDialog
    key="emoji-picker-dialog"
    appendTo="body"
    [dismissableMask]="true"
    styleClass="emoji-dialog">
    <ng-template pTemplate="headless">
      <div id="picker"></div>
    </ng-template>
  </p-confirmDialog>
}
