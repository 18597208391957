<div class="min-h-screen flex flex-col">
  <div
    class="2xl:max-w-screen-2xl xl:max-w-screen-xl md:max-w-screen-md sm:max-w-screen-sm max-w-full w-full mx-auto md:p-8 p-2">
    <app-toast />
    <app-navbar></app-navbar>
    <router-outlet></router-outlet>
  </div>
  <div class="mt-auto">
    <app-footer></app-footer>
  </div>
</div>
