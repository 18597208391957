<p-table
  [value]="history"
  [paginator]="true"
  [rows]="10"
  [first]="first"
  [lazy]="true"
  [totalRecords]="total"
  [tableStyle]="{ 'min-width': '70rem' }"
  [loading]="loading"
  (onPage)="pageChange($event)"
  dataKey="history.id">
  <ng-template pTemplate="header">
    <tr>
      <th class="w-[80px]">#</th>
      <th>Map</th>
      <th class="w-[200px]">Mapper</th>
      <th class="w-[160px]">Score</th>
      <th class="w-[120px]">Date</th>
    </tr>
  </ng-template>
  <ng-template pTemplate="emptymessage">
    <tr>
      <td colspan="5">
        <p-messages [enableService]="false" [(value)]="noHistoryMessage" />
      </td>
    </tr>
  </ng-template>
  <ng-template pTemplate="loadingbody">
    <ng-container *ngIf="history.length === 0">
      <ng-container *ngFor="let i of [0, 1, 2, 3, 4, 5, 6, 7, 8, 9]">
        <tr style="height: 64px">
          <td><p-skeleton /></td>
          <td>
            <div class="flex items-center">
              <p-skeleton size="42px" styleClass="me-4" />
              <p-skeleton width="30rem" />
            </div>
          </td>
          <td><p-skeleton /></td>
          <td><p-skeleton /></td>
          <td><p-skeleton /></td>
        </tr>
      </ng-container>
    </ng-container>
  </ng-template>
  <ng-template pTemplate="body" let-history>
    <tr>
      <td>{{ history.id }}</td>
      <td>
        <div class="flex items-center">
          <p-avatar
            [image]="history.cover"
            styleClass="flex me-4 rounded-md overflow-hidden"
            size="large" />
          <span class="text-ellipsis whitespace-nowrap overflow-hidden">
            {{ history.songName }}
          </span>
        </div>
      </td>
      <td class="text-ellipsis whitespace-nowrap overflow-hidden">
        {{ history.levelAuthorName }}
      </td>
      <td>
        <ng-container *ngIf="history.score as score">
          <div class="flex gap-x-1">
            <ng-container *ngFor="let step of score.steps">
              <span
                class="pi pi-circle-fill"
                [ngClass]="{
                  'step-skip': step === 'skip',
                  'step-fail': step === 'fail',
                  'step-success': step === 'success',
                  'step-empty': step === null
                }"></span>
            </ng-container>
          </div>
        </ng-container>
      </td>
      <td>{{ history.date }}</td>
    </tr>
  </ng-template>
</p-table>
