<p-card
  styleClass="ring-1 ring-white/15 bg-white/5 rounded-md flex items-center overflow-hidden">
  <div
    class="grid grid-cols-[auto_auto] mb-5 relative animate-rankedleSong"
    *ngIf="map !== null">
    <div class="flex flex-row gap-x-4 min-w-0">
      <p-avatar [image]="map.cover" styleClass="w-28 h-28" />
      <div class="flex flex-col gap-y-1 min-w-0">
        <span class="font-bold text-xl overflow-hidden text-ellipsis">{{
          map.songName
        }}</span>
        <div class="flex flex-col gap-y-0 min-w-0">
          <span class="text-sm">Mappé par: {{ map.levelAuthorName }}</span>
          <span class="text-sm"
            >bsr:
            <a
              [href]="'https://beatsaver.com/maps/' + map.id"
              target="_blank"
              class="inline-flex items-center gap-x-1"
              >{{ map.id }} <span class="pi pi-external-link text-sm"></span></a
          ></span>
        </div>
      </div>
    </div>
  </div>
  <div class="flex flex-col items-center gap-y-3">
    <div
      class="relative aspect-[15_/_2] mx-3 *:pointer-events-none select-none">
      <img [src]="baseWaveformUrl" class="w-full h-full" />
      <img
        [src]="unlockedWaveformUrl"
        class="absolute top-0 left-0 w-full h-full transition-all"
        [style]="unlockedWaveformStyle" />
      <img
        [src]="progressWaveformUrl"
        class="absolute top-0 left-0 w-full h-full"
        [style]="progressWaveformStyle" />
    </div>
    <div class="w-full flex items-center justify-between">
      <span class="w-28">
        <p-slider [(ngModel)]="volume" (onChange)="setVolume($event)" />
      </span>
      <span>{{ elapsedTime }}/00:30</span>
    </div>
    <p-button
      [text]="true"
      [rounded]="true"
      styleClass="w-16 h-16"
      (onClick)="playBtnClick()">
      <ng-template pTemplate="icon">
        <span [class]="'pi ' + statusIcon + ' text-[3.5rem]'"></span>
      </ng-template>
    </p-button>
  </div>
</p-card>
