<p-card styleClass="bg-transparent">
  <p-table [value]="[[]]">
    <ng-template pTemplate="caption"> Paramètres généraux </ng-template>
    <ng-template pTemplate="body">
      <tr>
        <td>Activer les messages privés</td>
        <td class="text-right">
          <p-inputSwitch
            [(ngModel)]="privateMessages"
            (onChange)="onSettingToggle($event, 'dm')"
            [disabled]="privateMessagesDisabled" />
        </td>
      </tr>
    </ng-template>
  </p-table>
</p-card>
