<p-table
  [value]="birthdays"
  [paginator]="true"
  [rows]="rows"
  [first]="first"
  [lazy]="true"
  [totalRecords]="total"
  [loading]="loading"
  sortField="member.name"
  (onLazyLoad)="onLazyLoad($event)">
  <ng-template pTemplate="header">
    <tr>
      <th pSortableColumn="member.name" class="min-w-[270px]">
        Membre <p-sortIcon field="member.name" />
      </th>
      <th pSortableColumn="date" class="min-w-[255px] w-[255px]">
        Date <p-sortIcon field="date" />
      </th>
    </tr>
    <tr>
      <th>
        <p-columnFilter
          type="text"
          field="member.name"
          matchMode="contains"
          placeholder="Rechercher un membre"
          class="block max-w-[250px]" />
      </th>
      <th>
        <p-columnFilter
          type="text"
          field="date"
          matchMode="contains"
          placeholder="Rechercher une date"
          class="block max-w-[250px]" />
      </th>
    </tr>
  </ng-template>
  <ng-template pTemplate="emptymessage">
    <tr style="height: 64px">
      <td colspan="2" class="text-center">Aucun résultat</td>
    </tr>
  </ng-template>
  <ng-template pTemplate="loadingbody">
    <ng-container *ngIf="birthdays.length === 0">
      <ng-container *ngFor="let i of [0, 1, 2, 3, 4, 5, 6, 7, 8, 9]">
        <tr style="height: 64px">
          <td class="min-w-[270px]">
            <div class="flex items-center">
              <p-skeleton shape="circle" size="42px" styleClass="me-4" />
              <p-skeleton width="10rem" />
            </div>
          </td>
          <td class="w-[255px]"><p-skeleton width="6rem" /></td>
        </tr>
      </ng-container>
    </ng-container>
  </ng-template>
  <ng-template pTemplate="body" let-birthday>
    <tr style="height: 64px">
      <td>
        <div class="flex items-center">
          <p-avatar
            [image]="birthday.member.avatar"
            styleClass="flex me-4"
            size="large"
            shape="circle" />
          <span>
            {{ birthday.member.name }}
          </span>
        </div>
      </td>
      <td>{{ birthday.date }}</td>
    </tr>
  </ng-template>
</p-table>
