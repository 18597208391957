<div class="py-6">
  <!-- Page title -->
  <h2>Carte interactive</h2>

  <!-- <app-not-bsfr-member *ngIf="!isBSFR; else page" /> -->
  <!-- <ng-template #page> -->
  <p-card
    header="Localisation des membres de BSFR"
    styleClass="rounded-md bg-black/30 backdrop-blur-[64px] shadow-lg ring-1 ring-white/10">
    <p-messages
      [value]="helpMessage"
      [enableService]="false"
      escape="false"
      styleClass="mb-6">
    </p-messages>
    <div
      style="height: 600px; outline: none"
      leaflet
      [leafletOptions]="options">
      <div [leafletLayers]="markers"></div>
    </div>
  </p-card>
  <!-- </ng-template> -->
</div>
